<template>
    <div :class="Show>0 ? 'MaskCss':''">
        <div class="XLR YC TG BottGray">
            <div></div>
            <FYArea @FromFYArea="Refresh()"></FYArea>
            <div v-if="$store.state.UserName !== '游客'" class="XR YC TG MP4" @click="Show=1">
                <img :src="'UserUpload/static/After2021/AddAN.png'" class="WH77">
            </div>
        </div>
        <div v-if="ToolsLoaded" class="BGLightGray">
            <div v-for="(Tool,index) in Tools" :key="index" :class="SeleI1===index ? 'BGY':'BottWhite'">
                <div class="XLR YC MP4">
                    <div @click="SetSeleI1(index,Tool.SiteShortName)">{{index+1}}.
                        <span class="T6 TB">{{Tool.SiteShortName}}</span>
                    </div>
                    <img src="../../../static/gif2/location.gif" @click="ShowMap=true,SeleI1=index" class="WH99 BDCircle">
                    <div class="AN ANGreen TG T2" @click="SetShowAct(index)">约球<br>碰撞</div>
                    <div class="XR YC AN5 AN51 TW">
                        <div v-if="SeleI1===index" @click="SeleI1=-1,aLinkKey=''">
                            <el-icon><ArrowUp/></el-icon>
                        </div>
                        <div v-else @click="SetSeleI1(index,Tool.SiteShortName)">
                            <el-icon><ArrowDown/></el-icon>
                        </div>
                    </div>
                </div>
                <div v-if="SeleI1===index">
                    <div v-if="Tool.ChangeScrollText" class="AN532 PA2">
                        <div class="XLR YC TW">场馆实时滚动字幕信息：
                            <div class="AN5 AN52" @click="SetScrollText(index,'更新完毕')">更新</div>
                        </div>
                        <input class="W90 MT2" type="text" v-model="Tool.ScrollTexts[0]"
                               @focus="Tool.ScrollTexts[0]=null"/>
                        <input class="W90 MT2" type="text" v-model="Tool.ScrollTexts[1]"
                               @focus="Tool.ScrollTexts[1]=null"/>
                        <input class="W90 MT2" type="text" v-model="Tool.ScrollTexts[2]"
                               @focus="Tool.ScrollTexts[2]=null"/>
                    </div>
                    <div v-else @click="SetScrollText(index,'变为更新')">
                        <div class="MP1 BoxGray1 BD_Radius1">
                            <MyMarquee :ShowItems="Tool.ScrollTexts" Width="90"></MyMarquee>
                        </div>
                    </div>
                    <div v-for="(Card,index2) in Tool.Cards" :key="index2" class="MTB2 BottGray">
                        <div v-if="$store.state.UserName === Card.UserName || $store.state.UserName === $store.state.HTM0"
                             class="XLR YC PA3">
                            <div class="TE BDCircle BoxBR WH77 XYC" @click="Delete(Card._id,Card.Title)">删</div>
                            <input v-if="$store.state.UserName === $store.state.HTM0" type="number" class="W6"
                                   v-model.number="Card.aIndex" @focus="Card.aIndex=null"
                                   @blur="SetaIndex(index,index2,Card.aIndex)"/>
                            <div class="XLR YC TG ML4" @click="Show=3,SeleI=index2">
                                <div :class="Card.CurrTimes===0 ? 'TF':''">
                                    卡主：{{Card.UserName}}
                                    <span class="TB">[{{Card.OneFYB/10}}元卡]&nbsp;</span>
                                    <div v-if="Card.SiteAdmins.indexOf($store.state.UserName)===-1" class="TE T2">
                                        {{Card.Text}}有效
                                    </div>
                                </div>
                                <div class="AN ANGreen TW ">
                                    <el-icon><ArrowRight/></el-icon>
                                </div>
                            </div>
                        </div>
                        <div v-else class="PA3 XLR YC TG" @click="Show=3,SeleI=index2">
                            <div :class="Card.CurrTimes===0 ? 'TF':''">
                                卡主：{{Card.UserName}}
                                <span class="TB">[{{Card.OneFYB/10}}元卡]</span>
                                <sup v-if="Card.SiteAdmins.indexOf($store.state.UserName)>=0 && Card.Cipher!=='' && Card.Cipher!=='建卡申请' && Card.AskN>0"
                                     class="TE T2">待刷:{{Card.AskN}}人次</sup>
                                <div v-if="Card.SiteAdmins.indexOf($store.state.UserName)>=0 && Card.Cipher!=='' && Card.Cipher!=='建卡申请'"
                                     class="TE T2">卡号:{{Card.Cipher}}
                                </div>
                                <div v-if="Card.SiteAdmins.indexOf($store.state.UserName)===-1" class="TE T2">
                                    {{Card.Text}}有效
                                </div>
                            </div>
                            <div class="AN ANGreen TW ">
                                <el-icon><ArrowRight/></el-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="XLR">
            <div></div>
            <div class="T5 TW XYC H4 W50 BGBlue" @click="SetShow(6)">
                <div class="XLR YC">我的用卡记录&nbsp;<el-icon>
                    <ArrowDown/>
                </el-icon>
                </div>
            </div>
            <div class="T5 TW XYC H4 W50  BGBlue" @click="SetShow(7)" style="margin-left: 1px">
                <div class="XLR YC">我的尊享&nbsp;<el-icon>
                    <ArrowRight/>
                </el-icon>
                </div>
            </div>
            <div></div>
        </div>
        <br>
        <div v-if="$store.state.UserName === $store.state.HTM0">
            <WXLink v-if="aLinkKey!==''" :WTitle="aLinkKey+'的共享卡已经启用，需用尊享付账，请点击该链接进入。'"
                    WText="备注：1元人民币＝10个尊享。尊享不足时，请及时补充。" :WLandTo="'FYGXK'+ItemStr+'FYGXK'+aLinkKey"
                    Pic="UserUpload/static/Img/KB.gif"></WXLink>
        </div>
        <FYMap v-if="ShowMap && SeleI1>-1" OP="Look"
               :Msg="{Name:Tools[SeleI1].SiteShortName,Addr:(Tools[SeleI1].City+'-'+Tools[SeleI1].Address),Lati:Tools[SeleI1].Lati,Longi:Tools[SeleI1].Longi}"
               @FromFYMap2="ShowMap=false"></FYMap>
    </div>
    <PopTitle v-if="Show===1" Title="共享卡建卡信息" @FromPopUp="Show=-1">
        <GXCardPub :ItemStr="ItemStr" @GXCardPub="Refresh()"></GXCardPub>
    </PopTitle>
    <PopTitle v-if="Show===6" Title="我的用卡记录" @FromPopUp="Show=-1">
        <div class="XR MTB2 TF PA2">消费尊享合计：{{MyCardsAllFYB}}</div>
        <table style="background-color:#fff;">
            <thead>
            <tr class="TF">
                <th>场地</th>
                <th>卡主</th>
                <th>人次</th>
                <th>尊享</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(MC,index) in MyCards" :key="index">
                <td>{{MC.SiteShortName}}</td>
                <td>{{MC.UserName}}</td>
                <td>{{MC.CardVal}}</td>
                <td>{{MC.CardVal*MC.OneFYB}}</td>
            </tr>
            </tbody>
        </table>
    </PopTitle>
    <PopTitle v-if="Show===7" :title="$store.state.UserName+'的尊享'" @FromPopUp="Show=-1,SeleI1=-1">
        <ZXGL></ZXGL>
    </PopTitle>
    <PopTitle v-if="Show===3 && SeleI1>-1 && SeleI>-1" :Title="Tools[SeleI1].Cards[SeleI].Title"
              @FromPopUp="Refresh()">
        <GXCardOpen :Id="Tools[SeleI1].Cards[SeleI]._id" :aLinkKey="Tools[SeleI1].Cards[SeleI].SiteShortName"
                    :ItemStr="ItemStr" @GXCardOpen="Refresh()"></GXCardOpen>
    </PopTitle>
    <PopOneAN v-if="Show===2 && SeleI1>-1" @FromPopUp="Show=-1">
        <Act0Main :ActDoc="ActDoc"></Act0Main>
    </PopOneAN>
</template>

<script>
    import {SetEnjoySite} from "@/components/M4_Sites/SiteShared.js"
    import {GetEndBool3Act} from '@/components/M3_Acts/ActShared.js'
    import {ReGetZX,Compare, myMongoDBPost} from '@/components/SharedVues/Shared0.js'
    import PopMenu from "../MyPopWin/PopMenu";
    import SubEntrance from "../SharedVues/SubEntrance";
    import PopTitle from "../MyPopWin/PopTitle";
    import MyInput from "../SharedVues/MyInput";
    import WXLink from "../IndependentVues/WXLink";
    import FYQ from "../IndependentVues/FYQ";
    import FYMap from "../SharedVues/FYMap";
    import PopOneAN from "../MyPopWin/PopOneAN";
    import PopUpDown from "../MyPopWin/PopUpDown";
    import SeleOneItem from "../FrontPage/SeleOneItem";
    import GXCardPub from "./GXCardPub";
    import GXCardOpen from "./GXCardOpen";
    import ZXGL from "./ZXGL";
    import Act0Main from "../M3_Acts/Act0Main";
    import FYArea from "../SharedVues/FYArea";
    import MySelectStr from "../SharedVues/MySelectStr";
    import MyMarquee from "../SharedVues/MyMarquee";
    import PopBott from "../MyPopWin/PopBott";

    export default {
        name: "GXCard",
        components: {
            PopBott,
            MyMarquee,
            MySelectStr,
            FYArea,
            Act0Main,
            ZXGL,


            GXCardOpen,
            GXCardPub,
            SeleOneItem,
            PopUpDown,
            PopOneAN,
            FYMap,
            FYQ,
            WXLink,
            MyInput,
            PopTitle,
            SubEntrance,
            PopMenu
        },
        props: ['ItemStr'],
        data() {
            return {
                CurrN: 0,
                Show: -1,
                ToolsLoaded: false,
                Tools: [],
                SeleI: -1,
                SeleI1: 0,
                TitleKey: '',
                InputI: false,
                ShowMap: false,
                aDoc: {},
                aLinkKey: '',
                MyCards: [],
                MyCardsAllFYB: 0,
                ActDoc: null,
                CurrZX: this.$store.state.ZX
            }
        },
        mounted() {
        },
        methods: {
            SetShow(N) {
                let that = this;
                if (this.$store.state.UserName === '游客') alert('游客操作无效，请先注册登录!'); else ReGetZX(that, function () {
                    that.Show = N;
                })
            },
            SetaIndex(index, index2, aIndex) {
                this.SeleI1 = index;
                let that = this, aObj = this.Tools[index].Cards[index2];
                aObj.aIndex = aIndex;
                this.Tools[index].Cards.splice(index2, 1, aObj);
                myMongoDBPost("UpdateDoc2/SharedCards", {UPTJ: {_id: aObj._id},UPObj: {aIndex: aIndex}}, function () {
                    that.Refresh();
                });
            },
            SetSeleI1(index, SiteShortName) {
                this.SeleI1 = index;
                this.aLinkKey = SiteShortName;
                let aObj = this.Tools[index];
                aObj.ChangeScrollText = false;
                this.Tools.splice(index, 1, aObj);
            },
            SetScrollText(index, Bool) {
                this.SeleI1 = index;
                let aObj = this.Tools[index];
                if (aObj.SiteAdmins && aObj.SiteAdmins.indexOf(this.$store.state.UserName) >= 0 || this.$store.state.UserName === this.$store.state.HTM0) {
                    aObj.ChangeScrollText = Bool === '变为更新';
                    this.Tools.splice(index, 1, aObj);
                    if (Bool === '更新完毕') myMongoDBPost("UpdateDoc2/SharedCards", {UPTJ: {_id: aObj.Id},UPObj: {ScrollTexts: aObj.ScrollTexts} }, function () {
                    });
                }
            },
            SetShowAct(index) {
                let that = this;
                console.log(that.Tools[index])
                SetEnjoySite(this.$store.state.UserName, that.ItemStr, that.Tools[index].SiteId, function () {
                    GetEndBool3Act(that.Tools[index].SiteShortName, that.ItemStr, that.$store.state.FYArea, function (ActDoc) {
                        that.ActDoc = ActDoc;
                        that.Show = 2;
                        that.SeleI1 = index;
                    })
                })
            },
            GetMyCards() {
                let that = this;
                this.MyCards = [];
                myMongoDBPost("GetDocs/Cards", {CUserName: that.$store.state.UserName}, function (Docs) {
                    that.MyCards = Docs;
                    that.MyCardsAllFYB = 0;
                    for (let i = 0; i < Docs.length; i++) that.MyCardsAllFYB += Docs[i].OneFYB * Docs[i].CardVal;
                    //console.log(Docs)
                })
            },
            //SeleItem(Val) {        this.Show = -1;        this.ItemStr = Val.split(":喜好")[0];        this.Refresh();      },
            Delete(Id, Title) {
                let that = this;
                let YN = confirm(Title + "===》确认要删除吗？");
                if (YN && Id !== undefined) myMongoDBPost("RemoveDoc/SharedCards", {_id: Id}, function () {
                    myMongoDBPost("RemoveDoc/Cards", {ToolsId: Id}, function () {
                        that.Refresh();
                    });
                });
            },
            Refresh() {
                let that = this;
                that.ToolsLoaded = false;
                that.Tools = [];
                that.Show = -1;
                console.log("SeleI1=", this.SeleI1)
                ReGetZX(that, function (ZX) {
                    that.CurrZX = ZX;

                    let X = that.$store.state.FYArea, Y = X.split(":");
                    let TJ = {ItemStr: that.ItemStr, Province: Y[1], City: Y[2], DC: Y[3]};
                    if (X.split(":")[0] === 'N') {
                        delete TJ.Province;
                        delete TJ.City;
                        delete TJ.DC;
                    }
                    if (X.split(":")[0] === 'P') {
                        delete TJ.City;
                        delete TJ.DC;
                    }
                    if (X.split(":")[0] === 'C') delete TJ.DC;
                    myMongoDBPost("GetDocs/SharedCards", TJ, function (Docs) {
                        console.log(Docs);
                        if (Docs !== '网络出错啦!' && Docs.length > 0) {
                            let aSiteShortNames = [];
                            that.Tools = [];
                            for (let i = 0, len = Docs.length; i < len; i++) {
                                //let A = aSiteShortNames.indexOf(Docs[i].SiteShortName) === -1;
                                let B = that.$store.state.LandTo.indexOf(Docs[i].SiteShortName) >= 0;
                                let C = Docs[i].SiteAdmins.indexOf(that.$store.state.UserName) >= 0;
                                if (B || C) Docs[i].aIndex = 0; else Docs[i].aIndex = i + 1;
                            }//先找出需要在第一行显示的内容
                            Docs.sort(Compare('aIndex'));
                            for (let i = 0, len = Docs.length; i < len; i++) {
                                if (aSiteShortNames.indexOf(Docs[i].SiteShortName) === -1) {
                                    aSiteShortNames.push(Docs[i].SiteShortName);
                                    that.Tools.push({
                                        SiteId: Docs[i].SiteId,
                                        SiteName: Docs[i].SiteName,
                                        SiteShortName: Docs[i].SiteShortName,

                                        Province: Docs[i].Province,
                                        City: Docs[i].City,
                                        DC: Docs[i].DC,

                                        Address: Docs[i].Address,
                                        Lati: Docs[i].Lati,
                                        Longi: Docs[i].Longi,
                                        ScrollTexts: Docs[i].ScrollTexts,
                                        SiteAdmins: Docs[i].SiteAdmins,
                                        Cards: [],
                                        ChangeScrollText: false
                                    })
                                }
                            }
                            console.log(aSiteShortNames);
                            for (let j = 0; j < aSiteShortNames.length; j++) {
                                for (let i = 0, len = Docs.length; i < len; i++)
                                    if (aSiteShortNames[j] === Docs[i].SiteShortName) that.Tools[j].Cards.push(Docs[i]);
                            }
                            that.GetMyCards();
                            that.ToolsLoaded = true;
                        }
                    });
                })
            }
        },
    }
</script>

<style scoped>

</style>
